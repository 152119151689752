.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  position: relative;
}

.upload-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); 
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.3); 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  overflow: hidden;
  
}

.upload-text {
  font-size: 20px;
  color: #131010;
  margin-bottom: 10rem;
  text-align: center;
  font-family: "Frank Ruhl Libre", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #131010;
  color: white;
  border-radius: 5px;
  margin-bottom: 20px;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.upload-button {
  padding: 10px 20px;
  background-color: #131010;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Frank Ruhl Libre", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.upload-button:hover {
  background-color: #444; 
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
}

.progress {
  height: 10px;
  background-color: #1f1b1b;
  width: 0;
  transition: width 0.4s ease;
}

.buttons-container {
  display: flex;
  justify-content: space-around; 
  width: 100%;
  padding: 10px;
}
.selected-files {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  /* margin-top: 20px; */
  overflow: auto;


}

.file {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 10px;
  border-radius: 5px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
  position: relative;
 border-radius: 50%;
}

.file img {
  width: 90px;
  height: 90px;
  margin-top: 10px;
  border-radius: 50%;

}

.file span {
  font-size: 14px;
  color: #131010;
  margin-top: 5px;
  text-align: center;
}

/* .remove-button {
  
 align-self: center;
 align-content: center;
 border-radius: 50%;

} */
.delete-icon {
  width: 30px;
  height: 30px;
  
  position: absolute;
  top: 0;
  right: 0;
  /* background-color: #ff4d4d; */
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 0;
}
/* .remove-button button {
  align-self: center;
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;

}

.remove-button button:hover {
  background-color: #ff1a1a;
} */
video {
  width: 90px;
  height: 90px;
  /* margin-top: 10px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  border-radius: 50%;

}